import request from './request'

const api = {
	// 获取列表
	lists: '/materialtitle/lists',
	// 添加
	add: '/materialtitle/add',
	// 删除
	dele: '/materialtitle/del',
	// 编辑
	edit: '/materialtitle/update',
	// 详情
	info: '/materialtitle/read',
	// 获取列表
	listsType: '/materialcate/lists',
}

export {
	request,
	api
}
